<template>
  <div class="kt-page--fluid kt-subheader--transparent full-page">
    <div class="kt-grid kt-grid--hor kt-grid--root">
      <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page pb-3">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
          <div class="kt-header__brand-logo my-5">
            <div class="kt-container">
              <router-link :to="{ name: 'base'}"><h2 class="text-primary">Sweet Shipping Cost</h2></router-link>
            </div>

          </div>
          <div class="kt-container kt-body kt-grid kt-grid--ver page-content">
            <div class="card card-custom ">
              <div class="card-body">
                <p>
                  <b>"Sweet Shipping Cost" "the App”</b> provides a skip cart at click "Add to cart" button and
                  cart popup <b>"the Service"</b> to merchants who use Shopify to power their stores. This Privacy
                  Policy describes how personal information is collected, used, and shared when you install or use the
                  App in connection with your Shopify-supported store.
                </p>

                <h5>Personal Information the App Collects</h5>
                <p>When you install the App, we are automatically able to access certain types of information from your
                  Shopify account:<br/>
                  Shop information as your store name, domain and your email. Data obtained via Shopify's APIs is only
                  used for the purposes of storing applications settings and never shared or stored outside of our
                  systems.
                <p/>

                <p>
                  We may send you service related emails according to your subscription. If you no longer wish to
                  receive
                  these emails, you may opt out of them by unsubscribing them.
                </p>

                <p>
                  We collect information like web requests, the data sent in response to such requests, the Internet
                  Protocol address (also called IP address, this one is a partial IP address, anonymised and encrypted),
                  the browser type, the browser language, and a timestamp for the request. Additionally, we collect the
                  following types of personal information from you and/or your customers once you have installed the
                  App:
                  We collect order information and name/location of customers. We use cookie in the pixel to track
                  visits.
                </p>

                <p>
                  We collect personal information directly from the relevant individual, through your Shopify account,
                  or
                  using the following technologies: “Cookies” are data files that are placed on your device or computer
                  and often include an anonymous unique identifier. For more information about cookies, and how to
                  disable
                  cookies, visit http://www.allaboutcookies.org. “Log files” track actions occurring on the Site, and
                  collect data including your IP address, browser type, Internet service provider, referring/exit pages,
                  and date/time stamps. “Web beacons,” “tags,” and “pixels” are electronic files used to record
                  information about how you browse the Site.
                </p>

                <h5>How Do We Use Your Personal Information?</h5>
                <p>
                  We use the personal information we collect from you in order to provide the Service and to operate the
                  App. Additionally, we use this personal information to: Communicate with you; Optimize or improve the
                  App; and Provide you with information or advertising relating to our products or services.
                </p>

                <h5>Sharing Your Personal Information</h5>
                <p>We do not share your personal information with any third party for marketing purposes. We may share
                  generic, non-identifiable information with our internally utilized third party tools such as Google
                  Analytics.
                </p>

                <p>
                  Finally, we may also share your Personal Information to comply with applicable laws and regulations,
                  to
                  respond to a subpoena, search warrant or other lawful request for information we receive, or to
                  otherwise protect our rights.
                </p>

                <h5>Your Rights</h5>
                <p>If you are a European resident, you have the right to access personal information we hold about you
                  and
                  to ask that your personal information be corrected, updated, or deleted. If you would like to exercise
                  this right, please contact us through the contact information below.
                </p>
                <p>
                  Additionally, if you are a European resident we note that we are processing your information in order
                  to
                  fulfill contracts we might have with you (for example if you make an order through the Site), or
                  otherwise to pursue our legitimate business interests listed above.
                </p>

                <p>Additionally, please note that your information will be transferred outside of Europe, including to
                  Canada and the United States.</p>

                <h5>Data Retention</h5>
                <p>When you place an order through the Site, we will maintain your Order Information for our records
                  unless
                  and until you ask us to delete this information.</p>

                <h5>Changes</h5>
                <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our
                  practices or for other operational, legal or regulatory reasons.</p>

                <h5>Contact Us</h5>
                <p>For more information about our privacy practices, if you have questions, or if you would like to make
                  a
                  complaint, please contact us by using the details provided below:</p>

                <p><b>Adress:</b><br/>
                  Harjumaa, Tallinn, Hobusepea tn 10-2, 10133</p>

                <p><b>Email:</b><br/>
                  apdigitalou@gmail.com</p>

                <p><b>Phone:</b><br/>
                  1-847-846-1868</p>

                <p><b>Skype:</b><br/>
                  apdigitalou</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer-component></footer-component>
    </div>
  </div>
</template>

<script>
import FooterComponent from '../components/FooterComponent'

export default {
  name: "Policy",
  components: {
    FooterComponent
  }
}
</script>

<style lang="scss">
</style>